/**
* Created by tangdq on 2018/8/2.
*/
<style lang="scss" scoped>
.new-add-mechanism {
    .box-card {
        margin-bottom: 10px;

        .add-label {
            font-size: 14px;
            color: #333333;
            letter-spacing: 0;
            font-weight: 700;
            border-left: 2px solid #319BF7;
            height: 14px;
            line-height: 14px;
            padding-left: 8px;
            margin-bottom: 15px;
        }

        .audit-form {
            float: left;
        }

        .add-title {
            width: 130px;
            text-align: right;
            float: left;
            margin-right: 10px;
        }

        .audit-form-edit {
            font-size: 14px;
            color: #909399;
        }

        .audit-back {
            background: rgba(255, 59, 48, 0.10);
            color: #ff3b30 !important;
        }

        .companyPC {
            width: 380px;
            text-align: left;
            color: #999999
        }
    }

    .color319 {
        background: #319BF7;
        color: #fff;
    }

    .color333 {
        color: #333333 !important;
    }
}
</style>
<style lang="scss">
.address-wrapper {
    max-width: 400px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #eee;
}

.distpicker-address-wrapper .address-container {
    height: 300px;
    overflow-y: auto;
}

.dialog-look-picture {
    overflow: hidden;

    .dialog-look-picture-custom {
        margin-top: 10px !important;
        height: calc(100% - 20px);
        overflow: hidden;
        min-width: 900px;
    }

    .el-dialog__body {
        padding: 10px;
        text-align: center;
        height: calc(100% - 80px);
        //position: relative;
        overflow-y: scroll;

        img {
            width: auto;
            max-width: 100%;
        }

        //overflow: hidden;
        //overflow-x: scroll;
    }

    .original-image-btn {
        position: absolute;
        left: 10px;
        top: 0;

        a {
            color: #319bf7;
        }

    }
}
</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <div class="new-add-mechanism">
                    <el-row>
                        <el-form>
                            <el-card class="box-card">
                                <p class="add-label">状态信息</p>
                                <el-form-item label="机构ID：" label-width="140px" label-position="right">
                                    <p style="color: #999">{{ info.orgId }}</p>
                                    <div>
                                        <el-button :disabled="!info.firstMerchantValidate"
                                                   :type="info.firstMerchantValidate ==1?'primary':'info'" plain
                                                   size="small">
                                            一级用户：{{ info.firstMerchantValidate == 1 ? '已开通' : '未开通' }}
                                        </el-button>
                                        <el-button :disabled="!info.secondMerchantValidate"
                                                   :type="info.secondMerchantValidate ==1?'primary':'info'" plain
                                                   size="small">
                                            二级用户：{{ info.secondMerchantValidate == 1 ? '已开通' : '未开通' }}
                                        </el-button>
                                        <el-button :disabled="!info.supplierValidate"
                                                   :type="info.supplierValidate ==1?'primary':'info'" plain
                                                   size="small">
                                            供应商：{{ info.supplierValidate == 1 ? '已开通' : '未开通' }}
                                        </el-button>
                                        <el-button :disabled="!info.operatorValidate"
                                                   :type="info.operatorValidate ==1?'primary':'info'" plain
                                                   size="small">
                                            运营商：{{ info.operatorValidate == 1 ? '已开通' : '未开通' }}
                                        </el-button>
                                    </div>
                                </el-form-item>
                            </el-card>
                            <el-card class="box-card">
                                <p class="add-label">机构信息</p>
                                <el-form-item :class="{'audit-back': info.selectList!=auditInfo.selectList}">
                                    <el-col class="audit-form">
                                        <div class="add-title">用户类型：</div>
                                        <div style="color: #999">{{ auditInfo.selectList }}</div>
                                    </el-col>
                                    <el-col class="audit-form-edit" v-if="info.selectList!=auditInfo.selectList">
                                        <div class="add-title">修改前：</div>
                                        <div style="color: #999">{{ info.selectList }}</div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item
                                    :class="{'audit-back': info.firstMerchantName!=auditInfo.firstMerchantName}"
                                    v-if="auditInfo.firstMerchantName">
                                    <el-col class="audit-form">
                                        <div class="add-title">所属一级用户名称：</div>
                                        <div style="color: #999">{{ auditInfo.firstMerchantName }}</div>
                                    </el-col>
                                    <el-col class="audit-form-edit"
                                            v-if="info.firstMerchantName!=auditInfo.firstMerchantName">
                                        <div class="add-title">修改前：</div>
                                        <div style="color: #999">{{ info.firstMerchantName }}</div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item :class="{'audit-back': info.operatorName !=auditInfo.operatorName}"
                                              v-if="auditInfo.operatorName">
                                    <el-col class="audit-form">
                                        <div class="add-title">所属运营商名称：</div>
                                        <div style="color: #999">{{ auditInfo.operatorName }}</div>
                                    </el-col>
                                    <el-col class="audit-form-edit" v-if="info.operatorName !=auditInfo.operatorName">
                                        <div class="add-title">修改前：</div>
                                        <div style="color: #999">{{ info.operatorName }}</div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item :class="{'audit-back': info.userName !=auditInfo.userName }">
                                    <el-col class="audit-form">
                                        <div class="add-title">用户名称：</div>
                                        <div style="color: #999">{{ auditInfo.userName }}</div>
                                    </el-col>
                                    <el-col class="audit-form-edit" v-if="info.userName !=auditInfo.userName ">
                                        <div class="add-title">修改前：</div>
                                        <div style="color: #999">{{ info.userName }}</div>
                                    </el-col>
                                </el-form-item>
                            </el-card>
                            <el-card class="box-card">
                                <p class="add-label">用户划分</p>
                                <el-form-item :class="{'audit-back': info.companyName !=auditInfo.companyName }">
                                    <el-col class="audit-form">
                                        <div class="add-title">公司名称：</div>
                                        <div style="color: #999">{{ auditInfo.companyName }}</div>
                                    </el-col>
                                    <el-col class="audit-form-edit" v-if="info.companyName !=auditInfo.companyName ">
                                        <div class="add-title">修改前：</div>
                                        <div style="color: #999">{{ info.companyName }}</div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item v-if="auditInfo.businessLicenseNumber"
                                              :class="{'audit-back': info.businessLicenseNumber !=auditInfo.businessLicenseNumber }">
                                    <el-col class="audit-form">
                                        <div class="add-title">营业执照号码：</div>
                                        <div style="color: #999">{{ auditInfo.businessLicenseNumber }}</div>
                                    </el-col>
                                    <el-col class="audit-form-edit"
                                            v-if="info.businessLicenseNumber !=auditInfo.businessLicenseNumber ">
                                        <div class="add-title">修改前：</div>
                                        <div style="color: #999">{{ info.businessLicenseNumber }}</div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item v-if="auditInfo.companyPAndC"
                                              :class="{'audit-back': info.companyPAndC !=auditInfo.companyPAndC }">
                                    <el-col class="audit-form">
                                        <div class="add-title">公司所在地：</div>
                                        <div style="color: #999">{{ auditInfo.companyPAndC }}</div>
                                    </el-col>
                                    <el-col class="audit-form-edit" v-if="info.companyPAndC !=auditInfo.companyPAndC ">
                                        <div class="add-title">修改前：</div>
                                        <div style="color: #999">{{ info.companyPAndC }}</div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item :class="{'audit-back': info.orgType != auditInfo.orgType}">
                                    <el-col class="audit-form">
                                        <div class="add-title">机构类型：</div>
                                        <div style="color: #999">{{ auditInfo.orgType }}</div>
                                    </el-col>
                                    <el-col class="audit-form-edit" v-if="info.orgType !=auditInfo.orgType ">
                                        <div class="add-title">修改前：</div>
                                        <div style="color: #999">{{ info.orgType }}</div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item :class="{'audit-back': info.businenssScope != auditInfo.businenssScope}">
                                    <el-col class="audit-form">
                                        <div class="add-title">经营范围：</div>
                                        <div style="color: #999">
                                            {{ auditInfo.businenssScope }}{{ auditInfo.otherBusinenss }}
                                        </div>
                                    </el-col>
                                    <el-col class="audit-form-edit"
                                            v-if="info.businenssScope !=auditInfo.businenssScope ">
                                        <div class="add-title">修改前：</div>
                                        <div style="color: #999">{{
                                                info.businenssScope
                                            }}{{ info.otherBusinenss }}
                                        </div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item v-if="auditInfo.serverCity"
                                              :class="{'audit-back': info.serverCity != auditInfo.serverCity}">
                                    <el-col class="audit-form">
                                        <div class="add-title">服务城市：</div>
                                        <div style="color: #999">{{ auditInfo.serverCity }}</div>
                                    </el-col>
                                    <el-col class="audit-form-edit" v-if="info.serverCity !=auditInfo.serverCity ">
                                        <div class="add-title">修改前：</div>
                                        <div style="color: #999">{{ info.serverCity }}</div>
                                    </el-col>
                                </el-form-item>
                            </el-card>
                            <el-card class="box-card">
                                <p class="add-label">联系人信息</p>
                                <el-form-item
                                    :class="{'audit-back': info.legalPersonName != auditInfo.legalPersonName||info.legalPersonPhone !=auditInfo.legalPersonPhone }"
                                    v-if="auditInfo.legalPersonName">
                                    <el-col class="audit-form">
                                        <div class="add-title">机构法人：</div>
                                        <div style="float: left;color: #999">{{ auditInfo.legalPersonName }}</div>
                                        <div style="float: left;margin-left: 20px;color:#999">
                                            {{ auditInfo.legalPersonPhone }}
                                        </div>
                                    </el-col>
                                    <el-col class="audit-form-edit"
                                            v-if="info.legalPersonName !=auditInfo.legalPersonName|| info.legalPersonPhone !=auditInfo.legalPersonPhone ">
                                        <div class="add-title">修改前：</div>
                                        <div style="float: left;color: #999">{{ info.legalPersonName }}</div>
                                        <div style="float: left;margin-left: 20px;color:#999">{{
                                                info.legalPersonPhone
                                            }}
                                        </div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item
                                    :class="{'audit-back': info.idType !=auditInfo.idType||info.idNo !=auditInfo.idNo}"
                                    v-if="auditInfo.idType&&auditInfo.idNo">
                                    <el-col class="audit-form">
                                        <div class="add-title">&nbsp;</div>
                                        <div style="float: left;color:#999">
                                            {{ auditInfo.idType != 2 ? '身份证' : '护照' }}
                                        </div>
                                        <div style="float: left;margin-left: 20px;color:#999">{{ auditInfo.idNo }}</div>
                                    </el-col>
                                    <el-col class="audit-form-edit"
                                            v-if="info.idType !=auditInfo.idType||info.idNo !=auditInfo.idNo ">
                                        <div class="add-title">修改前：</div>
                                        <div style="float: left;color:#999">{{ info.idType != 2 ? '身份证' : '护照' }}
                                        </div>
                                        <div style="float: left;margin-left: 20px;color:#999">{{ info.idNo }}</div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item
                                    :class="{'audit-back': info.businessContractName!= auditInfo.businessContractName||info.businessContractPhone != auditInfo.businessContractPhone||info.businessContractMail != auditInfo.businessContractMail }">
                                    <el-col class="audit-form">
                                        <div class="add-title">业务联系人：</div>
                                        <div style="float: left;color:#999">{{ auditInfo.businessContractName }}</div>
                                        <div style="float: left;margin-left: 20px;color:#999">
                                            {{ auditInfo.businessContractPhone }}
                                        </div>
                                        <div style="float: left;margin-left: 20px;color:#999">
                                            {{ auditInfo.businessContractMail }}
                                        </div>
                                    </el-col>
                                    <el-col class="audit-form-edit"
                                            v-if="info.businessContractName!= auditInfo.businessContractName||info.businessContractPhone != auditInfo.businessContractPhone||info.businessContractMail != auditInfo.businessContractMail">
                                        <div class="add-title">修改前：</div>
                                        <div style="float: left;color:#999">{{ info.businessContractName }}</div>
                                        <div style="float: left;margin-left: 20px;color:#999">
                                            {{ info.businessContractPhone }}
                                        </div>
                                        <div style="float: left;margin-left: 20px;color:#999">
                                            {{ info.businessContractMail }}
                                        </div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item
                                    :class="{'audit-back': info.financialContractName != auditInfo.financialContractName ||info.financialContractPhone != auditInfo.financialContractPhone }"
                                    v-if="auditInfo.financialContractName">
                                    <el-col class="audit-form">
                                        <div class="add-title">财务联系人：</div>
                                        <div style="float: left;color:#999">{{ auditInfo.financialContractName }}</div>
                                        <div style="float: left;margin-left: 20px;color:#999">
                                            {{ auditInfo.financialContractPhone }}
                                        </div>
                                    </el-col>
                                    <el-col class="audit-form-edit"
                                            v-if="info.financialContractName != auditInfo.financialContractName ||info.financialContractPhone != auditInfo.financialContractPhone">
                                        <div class="add-title">修改前：</div>
                                        <div style="float: left;color:#999">{{ info.financialContractName }}</div>
                                        <div style="float: left;margin-left: 20px;color:#999">
                                            {{ info.financialContractPhone }}
                                        </div>
                                    </el-col>
                                </el-form-item>
                            </el-card>
                            <el-card class="box-card" v-if="auditInfo.supplierSelect||auditInfo.operatorSelect">
                                <!--<el-card class="box-card">-->
                                <p class="add-label"><span>财务信息</span>
                                </p>
                                <el-form-item
                                    :class="{'audit-back': info.openAccountName != auditInfo.openAccountName }">
                                    <el-col>
                                        <div class="add-title">开户名称：</div>
                                        <div style="color:#999">{{ auditInfo.openAccountName }}</div>
                                    </el-col>
                                    <el-col class="audit-form-edit"
                                            v-if="info.openAccountName != auditInfo.openAccountName ">
                                        <div class="add-title">修改前：</div>
                                        <div style="color:#999">{{ info.openAccountName }}</div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item :class="{'audit-back': info.openAccountBank !=auditInfo.openAccountBank}">
                                    <el-col class="audit-form">
                                        <div class="add-title">开户银行：</div>
                                        <div style="color:#999">{{ auditInfo.openAccountBank }}</div>
                                    </el-col>
                                    <el-col class="audit-form-edit"
                                            v-if="info.openAccountBank !=auditInfo.openAccountBank ">
                                        <div class="add-title">修改前：</div>
                                        <div style="color:#999">{{ info.openAccountBank }}</div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item
                                    :class="{'audit-back': info.publicBankAccount !=auditInfo.publicBankAccount}">
                                    <el-col class="audit-form">
                                        <div class="add-title">对公银行账号：</div>
                                        <div style="color:#999">{{ auditInfo.publicBankAccount }}</div>
                                    </el-col>
                                    <el-col class="audit-form-edit"
                                            v-if="info.publicBankAccount !=auditInfo.publicBankAccount ">
                                        <div class="add-title">修改前：</div>
                                        <div style="color:#999">{{ info.publicBankAccount }}</div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item :class="{'audit-back': info.settleType !=auditInfo.settleType}">
                                    <el-col class="audit-form">
                                        <div class="add-title">结算方式：</div>
                                        <div style="color:#999" v-if="auditInfo.settleType==1">单结</div>
                                        <div style="color:#999" v-if="auditInfo.settleType==2">周结（T+3）</div>
                                        <div style="color:#999" v-if="auditInfo.settleType==3">月结（T+15）</div>
                                    </el-col>
                                    <el-col class="audit-form-edit" v-if="info.settleType !=auditInfo.settleType ">
                                        <div class="add-title">修改前：</div>
                                        <div style="color:#999" v-if="info.settleType==1">单结</div>
                                        <div style="color:#999" v-if="info.settleType==2">周结（T+3）</div>
                                        <div style="color:#999" v-if="info.settleType==3">月结（T+15）</div>
                                    </el-col>
                                </el-form-item>
                            </el-card>
                            <el-card class="box-card">
                                <p class="add-label"><span>资质信息</span>
                                </p>
                                <el-form-item>
                                    <el-col>
                                        <el-col class="audit-form">
                                            <div class="add-title">&nbsp;</div>
                                            <div style="color:#999">《工商营业执照》
                                                <el-button type="text"
                                                           @click="clickLookPicture('businessLincensePhoto',auditInfo.businessLincensePhoto)"
                                                           class="see-big">查看大图
                                                </el-button>
                                            </div>
                                        </el-col>
                                        <el-col class="audit-form" v-if="auditInfo.bankDepositPermit">
                                            <div class="add-title">&nbsp;</div>
                                            <div style="color:#999">《开户行许可证》</div>
                                        </el-col>
                                        <el-col class="audit-form" v-if="auditInfo.roadOperationLicense">
                                            <div class="add-title">&nbsp;</div>
                                            <div style="float: left;color:#999">《道路运营经营许可证》</div>
                                            <div style="color:#999" class="add-title">有效期：</div>
                                            <div style="color:#999">
                                                {{ auditInfo.roadOperationLicenseBegin | dateCus('yyyy-MM-dd') }} 至
                                                {{ auditInfo.roadOperationLicenseEnd | dateCus('yyyy-MM-dd') }}
                                            </div>
                                        </el-col>
                                        <el-col class="audit-form" v-if="auditInfo.travelBusinessLicensePhoto">
                                            <div class="add-title">&nbsp;</div>
                                            <div style="color:#999">《旅行社经营许可证》
                                                <el-button type="text"
                                                           @click="clickLookPicture('travelBusinessLicensePhoto',auditInfo.travelBusinessLicensePhoto)"
                                                           class="see-big">查看大图
                                                </el-button>
                                            </div>

                                        </el-col>
                                        <el-col class="audit-form" v-if="auditInfo.powerAttorney">
                                            <div class="add-title">&nbsp;</div>
                                            <div style="color:#999">《授权委托书》
                                                <el-button type="text"
                                                           @click="clickLookPicture('powerAttorney',auditInfo.powerAttorney)"
                                                           class="see-big">查看大图
                                                </el-button>
                                            </div>

                                        </el-col>
                                        <el-col class="audit-form" v-if="auditInfo.travelInsurance">
                                            <div class="add-title">&nbsp;</div>
                                            <div style="color:#999" class="add-title">《旅行社责任保险》</div>
                                            <div style="color:#999" class="add-title">有效期：</div>
                                            <div style="color:#999">
                                                {{ auditInfo.travelInsuranceBegin | dateCus('yyyy-MM-dd') }} 至
                                                {{ auditInfo.travelInsuranceEnd | dateCus('yyyy-MM-dd') }}
                                            </div>
                                        </el-col>
                                        <el-col class="audit-form" v-if="auditInfo.travelRelationship">
                                            <div class="add-title">&nbsp;</div>
                                            <div style="color:#999">《旅行社挂靠关系证明》</div>
                                        </el-col>
                                        <el-col class="audit-form" v-if="auditInfo.comment">
                                            <div class="add-title">其他：</div>
                                            <div style="color:#999">{{ auditInfo.comment }}</div>
                                        </el-col>
                                    </el-col>
                                </el-form-item>

                            </el-card>
                            <el-card class="box-card">
                                <p class="add-label">合同信息</p>
                                <el-form-item label-width="100px"
                                              v-if="auditInfo.contractJson&&auditInfo.contractJson.firstMerchant"
                                              label="一级用户">
                                    <el-col class="audit-form" v-for="(it,idx) in auditInfo.contractJson.firstMerchant"
                                            :key="idx">
                                        <!--<div class="add-title">{{it.userName}}</div>-->
                                        <div style="color:#999;width: 280px;" class="add-title"
                                             :class="{'audit-back':it.modified}">
                                            《{{ it.name }}》
                                        </div>
                                        <div style="color:#999" class="add-title">有效期：</div>
                                        <div style="float: left;color:#999"
                                             :class="{'audit-back':it.modified||it.effectiveDate!==it.oEffectiveDate||it.expirationDate!==it.oExpirationDate}">
                                            {{ it.effectiveDate }} 至
                                            {{ it.expirationDate }}
                                        </div>
                                        <div style="color:#999" class="add-title">合同编号：</div>
                                        <div style="float: left;color:#999"
                                             :class="{'audit-back':it.modified||it.contractNum!==it.oContractNum}">
                                            {{ it.contractNum }}
                                        </div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item label-width="100px"
                                              v-if="auditInfo.contractJson&&auditInfo.contractJson.secondMerchant"
                                              label="二级用户">
                                    <el-col class="audit-form" v-for="(it,idx) in auditInfo.contractJson.secondMerchant"
                                            :key="idx">
                                        <!--<div class="add-title">{{it.userName}}</div>-->
                                        <div style="color:#999;width: 280px" class="add-title"
                                             :class="{'audit-back':it.modified}">
                                            《{{ it.name }}》
                                        </div>
                                        <div style="color:#999" class="add-title">有效期：</div>
                                        <div style="float: left;color:#999"
                                             :class="{'audit-back':it.modified||it.effectiveDate!==it.oEffectiveDate||it.expirationDate!==it.oExpirationDate}">
                                            {{ it.effectiveDate }} 至 {{ it.expirationDate }}
                                        </div>
                                        <div style="color:#999" class="add-title">合同编号：</div>
                                        <div style="float: left;color:#999"
                                             :class="{'audit-back':it.modified||it.contractNum!==it.oContractNum}">>
                                            {{ it.contractNum }}
                                        </div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item label-width="100px"
                                              v-if="auditInfo.contractJson&&auditInfo.contractJson.supplier&&auditInfo.contractJson.supplier.length>0"
                                              label="供应商">
                                    <el-col class="audit-form" v-for="(it,idx) in auditInfo.contractJson.supplier"
                                            :key="idx">
                                        <!--                                        <div class="add-title">{{ it.userName || '&nbsp;' }}</div>-->
                                        <div style="color:#999;width: 280px" class="add-title"
                                             :class="{'audit-back':it.modified}">
                                            《{{ it.name }}》
                                        </div>
                                        <div style="color:#999" class="add-title">有效期：</div>
                                        <div style="float: left;color:#999"
                                             :class="{'audit-back':it.modified||it.effectiveDate!==it.oEffectiveDate||it.expirationDate!==it.oExpirationDate}">
                                            {{ it.effectiveDate | dateCus('yyyy-MM-dd') }} 至
                                            {{ it.expirationDate | dateCus('yyyy-MM-dd') }}
                                        </div>
                                        <div style="color:#999" class="add-title">
                                            合同编号：
                                        </div>
                                        <div style="float: left;color:#999"
                                             :class="{'audit-back':it.modified||it.contractNum!==it.oContractNum}">
                                            {{ it.contractNum }}
                                        </div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item label-width="100px"
                                              v-if="auditInfo.contractJson&&auditInfo.contractJson.operator&&auditInfo.contractJson.operator.length>0"
                                              label="运营商">
                                    <el-col class="audit-form" v-for="(it,idx) in auditInfo.contractJson.operator"
                                            :key="idx">
                                        <!--                                        <div class="add-title">{{ it.userName || '&nbsp;' }}</div>-->
                                        <div style="color:#999;width: 280px" class="add-title"
                                             :class="{'audit-back':it.modified}">
                                            {{ it.name || `&nbsp;` }}
                                        </div>
                                        <div style="color:#999" class="add-title">有效期：</div>
                                        <div style="float: left;color:#999"
                                             :class="{'audit-back':it.modified||it.effectiveDate!==it.oEffectiveDate||it.expirationDate!==it.oExpirationDate}">
                                            {{ it.effectiveDate | dateCus('yyyy-MM-dd') }} 至
                                            {{ it.expirationDate | dateCus('yyyy-MM-dd') }}
                                        </div>
                                        <div style="color:#999" class="add-title">
                                            合同编号：
                                        </div>
                                        <div style="float: left;color:#999"
                                             :class="{'audit-back':it.modified||it.contractNum!==it.oContractNum}">
                                            {{ it.contractNum }}
                                        </div>
                                    </el-col>
                                </el-form-item>
                            </el-card>
                            <el-card class="box-card"
                                     v-if="auditInfo.contractJson&&(auditInfo.contractJson.firstMerchant||auditInfo.contractJson.secondMerchant)">
                                <p class="add-label">财务信息</p>
                                <el-row>
                                    <p v-if="auditInfo.contractJson.secondMerchant"
                                       style="font-weight: 400;color:#999;font-size: 14px;line-height: 40px;padding-left: 30px;">
                                        财务不独立</p>
                                    <p v-if="auditInfo.contractJson.firstMerchant"
                                       style="font-weight: 400;color:#999;font-size: 14px;line-height: 40px;padding-left: 30px;">
                                        财务独立</p>
                                </el-row>
                            </el-card>
                        </el-form>
                    </el-row>
                </div>
            </div>
        </el-main>
        <!--查看大图  popup  begin-->
        <el-dialog class="dialog-look-picture" custom-class="dialog-look-picture-custom" title="查看" center
                   :visible.sync="lookPictureImg">
            <p v-if="lookPictureImgUrl">
                <el-button class="original-image-btn" type="text">
                    <a :href="lookPictureImgUrl" target="_blank">点击查看原图</a>
                </el-button>
                <see-pdf :src="lookPictureImgUrl" v-if="isSeePdf"></see-pdf>
                <img :src="lookPictureImgUrl" v-else>
                <!--                <img :src="lookPictureImgUrl">-->
            </p>
            <p style="text-align: center;padding-top: 20px" v-else>暂无照片</p>
        </el-dialog>
        <!--查看大图  popup end-->
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont" style="text-align: right">
                <el-row>
                    <el-col style="float: right">
                        <div style="float: left;font-size: 14px;line-height: 40px;padding-left: 10px"
                             v-if="$route.params.type!=1">
                            <i class="el-icon-warning" style="color: #999;margin-right: 10px"></i>提示：页面<span
                            style="color: #ff3b30">标红</span>处，即为修改过的内容
                        </div>
                        <div style="float: right">
                            <el-button plain @click="showDenied">拒绝</el-button>
                            <el-button type="primary" @click="showPass">通过</el-button>
                        </div>
                        <div style="float: right;width: 120px;line-height: 40px;margin-right: 20px">
                            {{ $route.params.type == 1 ? '新增' : '修改' }} | 审核结果
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-footer>
    </div>


</template>

<script type="text/ecmascript-6">
import {isFilePdf} from '@/common/js/Utils'
import seePdf from 'vue-pdf'
import {contractList} from '../../mechanism/newAddMechanism/index'

export default {
    name: 'auditDetail',
    //定义模版数据
    data() {
        return {
            agencyTypeList: ['汽车租赁公司', '旅行社', '酒店', '门店', '会展公司', '电子商务公司', '企业单位', '事业单位', '政府部门', '其他'],
            businenssScopeList: ['车辆', '导游', '门票', '酒店', '机票', '旅游(单元素)', '旅游(打包)', '交通票', '其他：'],
            info: {},
            auditInfo: {},
            qual: false,
            lookPictureImg: false,  //查看大图
            lookPictureImgUrl: '',  //大图url
            isSeePdf: false,
        }
    },
    components: {
        seePdf
    },
    //计算属性
    computed: {},
    activated() {
        this.getAuditInfo();
    },
    //主件被加载完成
    mounted: function () {
        // this.getAuditInfo();
    },
    //定义事件方法
    methods: {
        async getAuditInfo() {
            let id = this.$route.params.id;
            let res = await this.http('/galaxyApproval/get/' + id);
            if (res && res.success) {
                this.info = this.getForm(res.data.orgInfoDto);
                this.auditInfo = this.getForm(res.data.orgInfoEditDto, res.data.orgInfoDto);
            } else {
                this.messageError(res.errors[0].message)
            }

        },
        getCheckArray(str, list) {
            let arr = str.split('');
            let checkList = [];
            console.log(arr);
            for (let j = 0; j < arr.length; j++) {
                if (arr[j] == 1) checkList.push(list[j]);
            }
            return checkList.join('、');
        },
        clickLookPicture(id, pictureUrl) {  // 查看图片
            this.lookPictureImg = !this.lookPictureImg;
            this.lookPictureImgUrl = pictureUrl;
            let isPdfForm = {};
            isPdfForm = isFilePdf(id, pictureUrl);
            console.log(isPdfForm);
            this.isSeePdf = isPdfForm.isPdf;

        },
        getForm(data, originalInfo) {
            let form = data;
            form.selectList = [];
            let userTypeList = ['一级用户', '二级用户', '供应商', '运营商'];
            // if(data.userType.charAt(0) == '1') form.selectList.push(userTypeList[i])
            for (let i = 0; i < 4; i++) {
                if (data.userType.charAt(i) == '1') form.selectList.push(userTypeList[i])
            }
            form.selectList = form.selectList.join('、');
            form.supplierSelect = data.userType.charAt(2) == '1' ? true : false;
            form.operatorSelect = data.userType.charAt(3) == '1' ? true : false;
            form.firstSelect = data.userType.charAt(0) == '1' ? true : false;
            form.secondSelect = data.userType.charAt(1) == '1' ? true : false;
//                form.operatorName = data.operatorId;
//                form.firstName = data.firstMerchantId;
            if (data.companyCityName) form.companyPAndC = data.companyProvinceName + '/' + data.companyCityName + ' ' + (data.companyAddress || '');
            form.companyProvince = data.companyProvince;
            form.companyCity = data.companyCity;
            form.orgType = this.getCheckArray(data.orgType, this.agencyTypeList);
            form.businenssScope = this.getCheckArray(data.businessScope.substr(0, 9), this.businenssScopeList);
            if (data.businessScope.charAt(9) == 1) form.otherBusinenss = data.businessScope.substr(9, data.businenssScope.length - 1);
            if (data.serverCity) form.serverCity = data.serverCity ? data.serverCity.join(',') : '';
            form.businessLincense = data.businessLincense == 1 ? true : false;
            form.bankDepositPermit = data.bankDepositPermit == 1 ? true : false;
            form.roadOperationLicense = data.roadOperationLicense == 1 ? true : false;
            form.travelBusinessLicense = data.travelBusinessLicense == 1 ? true : false;
            form.travelInsurance = data.travelInsurance == 1 ? true : false;
            form.travelRelationship = data.travelRelationship == 1 ? true : false;
            // form.firstMerchantContractFile = data.firstMerchantContractFile == 1 ? true : false;
            // form.operatorContractFile = data.operatorContractFile == 1 ? true : false;
            // form.secondMerchantContractFile = data.secondMerchantContractFile == 1 ? true : false;
            // form.supplierContractFile = data.supplierContractFile == 1 ? true : false;
            if (originalInfo) {
                const oContractJson = JSON.parse(originalInfo.contractJson);
                form.contractJson = form.contractJson ? JSON.parse(form.contractJson) : {};
                form.contractJson['supplier'] = [];
                form.contractJson['operator'] = [];
                for (const key in form.contractJson) {
                    form.contractJson[key].forEach((item) => {
                        const oItem = oContractJson[key] ? oContractJson[key].find(({name}) => name === item.name) : undefined;
                        if (oItem) {
                            item.oEffectiveDate = oItem.effectiveDate
                            item.oExpirationDate = oItem.expirationDate
                            item.oContractNum = oItem.contractNum
                        } else {
                            item.modified = true;
                        }
                    })
                }
                contractList.forEach(({contractType}, idx) => {
                    if ([2, 3].includes(idx)) {
                        contractType.forEach((item) => {
                            const obj = {
                                name: `${item.name}`,
                                effectiveDate: form[`${item.key}Begin`],
                                oEffectiveDate: originalInfo[`${item.key}Begin`],
                                expirationDate: form[`${item.key}End`],
                                oExpirationDate: originalInfo[`${item.key}End`],
                                contractNum: form[`${item.key}No`],
                                oContractNum: originalInfo[`${item.key}No`]
                            }
                            if (obj.effectiveDate || obj.expirationDate || obj.contractNum)
                                form.contractJson[idx === 2 ? 'supplier' : 'operator'].push(obj)
                        })
                    }
                })
                if (form.supplierOtherContract) {
                    const oSupplierOtherContract = JSON.parse(originalInfo.supplierOtherContract);
                    JSON.parse(form.supplierOtherContract).forEach((item) => {
                        const oItem = oSupplierOtherContract.find(({contractName}) => contractName === item.contractName) || {};
                        const obj = {
                            userName: "其他",
                            name: `${item.contractName}`,
                            effectiveDate: item.contractBegin,
                            oEffectiveDate: oItem.contractBegin,
                            expirationDate: item.contractEnd,
                            oExpirationDate: oItem.contractEnd,
                            contractNum: item.contractNo,
                            oContractNum: oItem.contractNo,
                            modified: !oItem.contractName && form.supplierOtherContract !== originalInfo.supplierOtherContract
                        }
                        form.contractJson.supplier.push(obj)
                    })
                }
                if (form.operatorOtherContract) {
                    const oOperatorOtherContract = JSON.parse(originalInfo.operatorOtherContract);
                    JSON.parse(form.operatorOtherContract).forEach((item) => {
                        const oItem = oOperatorOtherContract.find(({contractName}) => contractName === item.contractName) || {};
                        const obj = {
                            userName: "其他",
                            name: `${item.contractName}`,
                            effectiveDate: item.contractBegin,
                            oEffectiveDate: oItem.contractBegin,
                            expirationDate: item.contractEnd,
                            oExpirationDate: oItem.contractEnd,
                            contractNum: item.contractNo,
                            oContractNum: oItem.contractNo,
                            modified: !oItem.contractName && form.operatorOtherContract !== originalInfo.operatorOtherContract
                        }
                        form.contractJson.operator.push(obj)
                    })
                }

            }
            return form;
        },
        showPass() {
            this.$prompt('此操作不可逆，请确认内容已被仔细审阅，没有遗漏。', '确定通过审核吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPlaceholder: '通过理由…',
                inputType: 'textarea'
            }).then(({value}) => {
                this.approvalPass(value);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });
            });
        },
        showDenied() {
            this.$prompt('', '请输入拒绝通过的原因：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPlaceholder: '如哪项内容填写错误，或哪项内容不符合要求等…',
                inputType: 'textarea',
                inputValidator(val) {
                    if (!val)
                        return '请输入拒绝理由'
                }
            }).then(({value}) => {
                if (!value) return this.messageError('请输入拒绝理由');
                this.approvalDenied(value);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });
            });
        },
        async approvalPass(val) {
            let form = {id: this.$route.params.id, passDesc: val};
            let res = await this.http('/galaxyApproval/approvalPass', form, 'POST');
            if (res && res.success) {
                this.$router.back()
            } else {
                this.messageError(res.errors[0].message)
            }
        },
        async approvalDenied(val) {
            let form = {id: this.$route.params.id, approvalDesc: val};
            let res = await this.http('/galaxyApproval/approvalDenied', form, 'POST');
            if (res && res.success) {
                this.$router.back()
            } else {
                this.messageError(res.errors[0].message)
            }
        }

    },
    //监听模版变量
    watch: {}

}
</script>

